import { Location } from '@zupr/types/fo'
import { capitalize } from '@zupr/utils/string'
import classnames from 'classnames'
import { useContext } from 'react'

import RouteContext from '../../../context/route'
import ExternalLink from '../../router/link-external'

import Facebook from '../../../../svg/facebook.svg'
import Instagram from '../../../../svg/instagram.svg'
import Whatsapp from '../../../../svg/whatsapp.svg'

import '../../../../scss/react/components/action-links.scss'

interface SocialLinkProps {
    location: Location
    href?: string
    platform: 'facebook' | 'whatsapp' | 'instagram'
    className?: string
    onClick?: () => void
    noIcon?: boolean
    iconOnly?: boolean
}

const SocialLink = ({
    href,
    location,
    className,
    onClick,
    platform,
    noIcon,
    iconOnly,
}: SocialLinkProps) => {
    const { trackEvent } = useContext(RouteContext)

    const handleClick = () => {
        // track event
        trackEvent({
            id: location.id,
            action: `clickSocial${capitalize(platform)}`,
        })

        if (onClick) onClick()
    }

    return (
        <ExternalLink
            className={classnames(
                'social-link',
                `social-link-${platform}`,
                className
            )}
            href={href || location[platform]}
            onClick={handleClick}
            aria-label={`${location.title} on ${capitalize(platform)}`}
        >
            {!noIcon && (
                <span className={`btn-social social-${platform}`}>
                    {platform === 'facebook' && <Facebook />}
                    {platform === 'whatsapp' && <Whatsapp />}
                    {platform === 'instagram' && <Instagram />}
                </span>
            )}
            {!iconOnly && capitalize(platform)}
        </ExternalLink>
    )
}

interface WhatsappLinkProps
    extends Omit<SocialLinkProps, 'platform' | 'href'> {}

export const WhatsappLink = ({ ...props }: WhatsappLinkProps) => (
    <SocialLink
        platform="whatsapp"
        href={`https://api.whatsapp.com/send?phone=${props.location.whatsapp.replace(
            '+',
            ''
        )}`}
        {...props}
    />
)

export default SocialLink

import { Field } from '@zupr/types/form'

import Checkmark from '../../../../svg/checkmark.svg'

interface ValidProps {
    field: Field
    valid?: boolean // props override for field
}

const Valid = ({ field, valid }: ValidProps) => {
    const isValid = typeof valid === 'boolean' ? valid : field.isValid?.()
    if (isValid) return <Checkmark className="checkmark" />
    return <>{'*'}</>
}

export default Valid
